@tailwind base;
@tailwind components;
@tailwind utilities;

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

body {
    @apply min-h-screen bg-neutral-100 text-white;
}

.segment {
    @apply bg-white text-black p-4 rounded shadow;
}

.segment .secondary {
    @apply bg-white border-neutral-300 border shadow-none p-2;
}

.segment .alert {
    @apply bg-yellow-400 border-yellow-300 border shadow-none p-2;
}

.form .input {
    @apply bg-white text-black placeholder-neutral-400 border-neutral-300 border rounded;
    @apply p-1 flex;
}
.form .input svg {
    @apply flex-none h-6 mr-1;
}
.form .input input {
    @apply bg-transparent border-0 flex w-full;
}

.form label {
    @apply block mb-0.5 text-neutral-700;
}

.form .primary-button {
    @apply bg-primary px-4 text-white hover:bg-primary-offset rounded;
}
.form .secondary-button {
    @apply bg-primary-offset px-2 text-white hover:bg-primary rounded;
}

.form select {
    @apply h-8 p-1;
    @apply bg-white text-black placeholder-neutral-700 border-neutral-300 border rounded;
}

.form .error {
    @apply border border-red-500;
}
.form .error-msg {
    @apply text-red-500;
}

.primary-button {
    @apply bg-primary hover:bg-primary-offset rounded p-2 text-white shadow;
}

h1 {
    @apply text-4xl;
}

h2 {
    @apply text-3xl;
}
h3 {
    @apply text-2xl;
}

.table {
    @apply grid bg-white border-neutral-300 border p-0 rounded overflow-hidden;
}

.table > .header {
    @apply bg-neutral-50 py-2 px-4 border-t-0 text-neutral-700;
}
.table > div {
    @apply border-t border-neutral-300 p-4;
}

.pill-button {
    @apply w-fit h-fit rounded px-1 shadow flex flex-row hover:cursor-pointer;
}
.pill-button svg {
    @apply h-5 inline my-auto mr-1;
}
.pill-button a {
    @apply my-auto;
}
